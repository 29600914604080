import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/css/output.css';
import './assets/css/input.css';
import './assets/css/global.css';


const root = document.getElementById('root') || document.createElement('div');

if (!root) {
    throw new Error('Root element not found');
}

// Use createRoot to render the app
const reactRoot = ReactDOM.createRoot(root);

// Render the app inside the root
reactRoot.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

