import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {HomeIcon} from "@heroicons/react/16/solid";
import Breadcrumb from "../assets/components/breadcrumb";

const Home = () => {

    const navigate = useNavigate();
    const [admin, setAdmin] = useState({});

    useEffect(() => {
        const token = localStorage.getItem('adminToken');
        const admin = localStorage.getItem('administrator');
        if (!token) {
            navigate('/login');
        } else if (admin) {
            setAdmin(JSON.parse(admin));
        }
    }, [navigate]);


    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Dashboard', url: '/' },
    ];

    return (
        <>
            <div className="mt-3 flex justify-between">
                <div>
                    <Breadcrumb items={breadcrumbItems} />
                </div>

                <div>
                    <h5 className="font-bold">User</h5>
                </div>
            </div>


            <div className="pt-0 ">
                <h1 className="font-bold text-lg">Home</h1>
            </div>

            <div className="mt-3 flex justify-between">
            

                <div>
                <h5 className="font-bold capitalize ">{admin.designation ? admin.designation : 'Designation Unavailable'}</h5>
                </div>
            </div>


            <h1 className="font-bold capitalize">Welcome {admin.username ? admin.username : ''}</h1>
            <h5 className="font-bold">id#:  {admin.id ? admin.id : ''}</h5>
     


            <div className="grid grid-cols-2 grid-rows-2 gap-4">
                <div className="bg-yellow-700 h-40">
                    {/* Top-left box content */}
                </div>
                <div className="bg-blue-500 h-40">
                    {/* Top-right box content */}
                </div>
                <div className="bg-green-500 h-40">
                    {/* Bottom-left box content */}
                </div>
                <div className="bg-red-500 h-40">
                    {/* Bottom-right box content */}
                </div>
            </div>
            
        </>
    );
};

export default Home;