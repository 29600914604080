import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {Route, Routes} from "react-router-dom";
import Sidebar from "./assets/components/sidebar";
import Breadcrumb from "./assets/components/breadcrumb";
import Home from "./User/Home";
import HeadlineMarquee from "./assets/components/headlineMarquee";
import Footer from "./assets/components/footer";
import AllPermits from "./User/Permits/AllPermits";
import TimeCalculatorUTC from "./Tools/TimeCalculatorUTC";
import Forms from "./Downloads/Forms";
import {HomeIcon} from "@heroicons/react/16/solid";
import PermitDetails from './User/Permits/PermitDetails';
import AddOtherPermitDetails from "./User/Permits/AddOtherPermitDetails";

const Dashboard = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
            <div className="flex min-h-screen max-h-screen h-screen">
                <div className={`transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-64'} max-w-64 min-w-16`}>
                    <Sidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
                </div>

                <div className="flex-grow flex flex-col bg-neutral-200 justify-between">
                    <div className="flex-none">
                        <HeadlineMarquee />
                    </div>
                    <div className="flex-grow overflow-y-auto px-3">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            {/* Permits */}
                            <Route path="/permit/all-requests" element={<AllPermits />} />
                            <Route path="/permit/details/:ref" element={<PermitDetails />} />
                            <Route path="/permit/complete-form/:ref" element={<AddOtherPermitDetails />} />

                            {/* Tools */}
                            <Route path="/tools/time-calculator-utc" element={<TimeCalculatorUTC />} />

                            {/* Downloads */}
                            <Route path="/downloads/forms" element={<Forms />} />
                        </Routes>
                    </div>
                    <div className="flex-none">
                        <Footer />
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
