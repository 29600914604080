import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Card, CardContent, Typography, CircularProgress, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination } from '@mui/material';
import { BACKEND_URL } from '../../Default';

const PermitDetails = () => {
    const { ref } = useParams();
    const navigate = useNavigate();
    const [permitData, setPermitData] = useState(null);
    const [caaFormPath, setCaaFormPath] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        const fetchPermitDetails = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/requests/request/${ref}`);
                const permit = response.data[0];

                setPermitData(permit);
                setLoading(false);



                const caaGetFile = await axios.get(`${BACKEND_URL}/pdf/caa-form/${ref}`)
                const { CAAForm } = caaGetFile.data;

                if (CAAForm) {
                    setCaaFormPath(CAAForm);
                } else {
                    setCaaFormPath(null); // Set to null if CAAForm is not available
                }

            } catch (error) {
                setError(error.message);
                setLoading(false);
            }
        };

        fetchPermitDetails();
    }, [ref]);

    const handleDownload = async (fileUrl, fileName, requestId) => {
        try {
            const response = await fetch(fileUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const downloadFileName = `${requestId}-${fileName}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', downloadFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <CircularProgress />
            </div>
        );
    }

    if (error) {
        return <div className="text-center text-red-500">Error: {error}</div>;
    }

    if (!permitData) {
        return <p className="text-center">Details not available</p>;
    }

    const files = permitData.documents?.map(doc => ({
        ...doc,
        file_url: `${BACKEND_URL}/${doc.file_path.replace(/\\/g, '/')}`
    })) || [];

    return (
        <div className="container mx-auto p-4">
            <Card className="mb-6 grid grid-cols-2">
                <div>
                    <CardContent>
                        <Typography variant="h4" className="font-bold mb-4">Permit Details</Typography>
                        <Typography variant="body1" className="mb-2">
                            <strong>Request ID:</strong> {permitData.request_id || 'Not available'}
                        </Typography>
                        <Typography variant="body1" className="mb-2">
                            <strong>Aircraft Nature:</strong> {permitData.request_data?.aircraftNature || 'Not available'}
                        </Typography>
                        <Typography variant="body1" className="mb-2">
                            <strong>Sub Category:</strong> {permitData.request_data?.subCategory || 'Not available'}
                        </Typography>
                        <Typography variant="body1" className="mb-2">
                            <strong>Operator Name:</strong> {permitData.request_data?.operator?.name || 'Not available'}
                        </Typography>
                        <Typography variant="body1" className="mb-2">
                            <strong>DGR:</strong> {permitData.request_data?.isDGR ? 'Yes' : 'No'}
                        </Typography>
                    </CardContent>
                </div>

                <div>
                    <div>
                        <button
                            className="bg-red-600 hover:bg-red-900 px-3 py-1 text-neutral-100 rounded-lg mt-4"
                            onClick={() => navigate(`/permit/complete-form/${ref}`)}
                        >
                            Generate CAA Form
                        </button>
                    </div>

                    {caaFormPath && (
                        <div>
                            <button onClick={() => handleDownload(`${BACKEND_URL}/${caaFormPath.replace(/\\/g, '/')}`, 'caa-form.docx', permitData.request_id)}>
                                Download CAA Form
                            </button>
                        </div>
                    )}
                </div>
            </Card>

            <Typography variant="h5" className="font-bold mb-4">Files</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Filename</strong></TableCell>
                            <TableCell><strong>Download</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {files.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((file, index) => (
                            <TableRow key={index}>
                                <TableCell>{file.document_name}</TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleDownload(file.file_url, file.document_name, permitData.request_id)}
                                    >
                                        Download
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={files.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </div>
    );
};

export default PermitDetails;
