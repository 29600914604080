import React from 'react';
import {AUTHOR, SYSTEM_NAME, SYSTEM_VERSION} from "../wordings/constants";

const Footer = () => {
    return (
        <div className="flex bg-fts-blue w-full justify-between py-2 text-gray-300 text-sm px-8">
            <div>
                <span dangerouslySetInnerHTML={{ __html: SYSTEM_NAME }}></span>
                <span className="ml-3">{SYSTEM_VERSION}</span>
            </div>
            <div>
                <span>{AUTHOR}</span>
            </div>
        </div>
    );
};

export default Footer;