import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../../assets/components/breadcrumb";
import { BACKEND_URL } from "../../Default";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Checkbox,
    Select,
    MenuItem,
    FormControl,
    InputLabel
} from "@mui/material";

const AllPermits = () => {
    const navigate = useNavigate();
    const [permits, setPermits] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedField, setSortedField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedRows, setSelectedRows] = useState([]);
    const [filterStatus, setFilterStatus] = useState('');
    const [sortDate, setSortDate] = useState('');

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/dashboard' },
        { label: 'Permit', url: '' },
        { label: 'Management', url: '/permit/management' },
    ];

    const formatPermitType = (landing, overflying) => {
        if (landing && overflying) return 'Both';
        if (landing) return 'Landing Permit';
        if (overflying) return 'Overflying Permit';
        return 'None';
    };

    useEffect(() => {
        const fetchPermits = async () => {
            const userData = JSON.parse(localStorage.getItem("user"));
            if (!userData) {
                console.error('User data not found');
                return;
            }

            try {
                const response = await axios.get(`${BACKEND_URL}/requests/all`);
                const permitData = response.data.map(permit => {
                    let requestData = {};
                    try {
                        requestData = JSON.parse(permit.request_data);
                    } catch (e) {
                        console.error('Error parsing request_data:', e);
                    }
                    return {
                        id: permit.id,
                        requestId: permit.request_id,
                        needLandingPermits: requestData.needLandingPermits ? 'Yes' : 'No',
                        needOverflyingPermits: requestData.needOverflyingPermits ? 'Yes' : 'No',
                        name: requestData.operator?.name,
                        type: requestData.aircraftNature,
                        subCategory: requestData.subCategory,
                        isDGR: requestData.isDGR ? 'Yes' : 'No',
                        status: permit.request_status, // Update this as per your status logic
                        applicant: requestData.operator?.name,
                        submissionDate: new Date(permit.added_date).toLocaleDateString(),
                        approvalDate: new Date(permit.updated_date).toLocaleDateString(),
                        schedule: requestData.schedules[0],
                    };
                });
                setPermits(permitData);
            } catch (error) {
                console.error('Error fetching permits:', error);
            }
        };

        fetchPermits();
    }, []);

    const handleRowSelect = (event, id) => {
        const selectedIndex = selectedRows.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (id) => selectedRows.indexOf(id) !== -1;

    const handleSort = (field) => {
        const isAsc = sortedField === field && sortDirection === 'asc';
        setSortedField(field);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const handleFilterChange = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleDateSortChange = (event) => {
        setSortDate(event.target.value);
    };

    const filteredData = permits.filter((permit) => {
        const matchesSearchTerm = permit.name && permit.name.toLowerCase().includes(searchTerm.toLowerCase());
        const matchesFilterStatus = filterStatus ? permit.status === filterStatus : true;
        return matchesSearchTerm && matchesFilterStatus;
    });

    const sortedData = filteredData.slice().sort((a, b) => {
        let comparison = 0;
        if (sortedField) {
            const fieldA = a[sortedField] ?? '';
            const fieldB = b[sortedField] ?? '';
            comparison = fieldA.localeCompare(fieldB);
            comparison = sortDirection === 'asc' ? comparison : -comparison;
        }
        if (sortDate) {
            const dateA = new Date(a.submissionDate);
            const dateB = new Date(b.submissionDate);
            comparison = sortDate === 'latest' ? dateB - dateA : dateA - dateB;
        }
        return comparison;
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <div className="w-full min-w-full min-h-[88vh] px-4">
            <main>
                <div className="mt-3">
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                <div className="mt-3">
                    <h1 className="font-bold text-gray-800 text-xl">Permit Management</h1>
                    <div className="grid grid-cols-4 gap-4 my-2">
                        <div>
                            <TextField
                                label="Search"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                variant="outlined"
                                size="small"
                                className="bg-neutral-50"
                            />
                        </div>
                        <div className="col-start-3">
                            <FormControl variant="outlined" size="small" className="w-48">
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={filterStatus}
                                    onChange={handleFilterChange}
                                    label="Status"
                                    className="bg-neutral-50"
                                >
                                    <MenuItem value=""><em>All</em></MenuItem>
                                    <MenuItem value="COMPLETE">Complete</MenuItem>
                                    <MenuItem value="SUBMITTED">Submitted</MenuItem>
                                    <MenuItem value="RECEIVED">Received</MenuItem>
                                    <MenuItem value="GENERATED">Generated</MenuItem>
                                    <MenuItem value="INCOMPLETE">Incomplete</MenuItem>
                                    <MenuItem value="NOT APPROVED">Not Approved</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl variant="outlined" size="small" className="w-48">
                                <InputLabel>Sort Date</InputLabel>
                                <Select
                                    value={sortDate}
                                    onChange={handleDateSortChange}
                                    label="Sort Date"
                                    className="bg-neutral-50"
                                >
                                    <MenuItem value=""><em>None</em></MenuItem>
                                    <MenuItem value="latest">Latest</MenuItem>
                                    <MenuItem value="oldest">Oldest</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell><strong>Ref No #</strong></TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortedField === 'name'}
                                            direction={sortDirection}
                                            onClick={() => handleSort('name')}
                                        >
                                            <strong>Name</strong>
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell><strong>Request</strong></TableCell>
                                    <TableCell><strong>Purpose</strong></TableCell>
                                    <TableCell><strong>Aircraft Nature</strong></TableCell>
                                    <TableCell><strong>DGR</strong></TableCell>
                                    <TableCell><strong>Status</strong></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((permit) => (
                                    <TableRow
                                        onClick={() => navigate(`/permit/details/${permit.requestId}`)}
                                        className="hover:bg-blue-50 hover:cursor-pointer" key={permit.id}>
                                        <TableCell className='uppercase'>{permit.requestId}</TableCell>
                                        <TableCell>{permit.name}</TableCell>
                                        <TableCell>
                                            {permit.schedule?.needLandingPermits && permit.schedule?.needOverflyingPermits
                                                ? 'Both'
                                                : permit.schedule?.needLandingPermits
                                                    ? 'Landing'
                                                    : permit.schedule?.needOverflyingPermits
                                                        ? 'Overflying'
                                                        : 'NONE'}
                                        </TableCell>

                                        <TableCell>{permit.type}</TableCell>
                                        <TableCell>{permit.subCategory}</TableCell>
                                        <TableCell>{permit.isDGR}</TableCell>
                                        <TableCell className={permit.status === 'INCOMPLETE'
                                            ? 'text-red-500'
                                            : permit.status === 'COMPLETE'
                                                ? 'text-green-500'
                                                : permit.status === 'NOT APPROVED'
                                                    ? 'text-orange-500'
                                                    : ''
                                        }>{permit.status}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={sortedData.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </TableContainer>
                </div>
            </main>
        </div>
    );
};

export default AllPermits;
