import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Footer from "../assets/components/footer";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { BACKEND_URL } from '../Default.js'; // Make sure you have the BACKEND_URL in your config.js

const Login = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const token = localStorage.getItem('adminToken');
        if (!token) {
            navigate('/login');
        } else {
            navigate('/dashboard')
        }
    }, [navigate]);

    const [formData, setFormData] = useState({
        UserName: '',
        Password: '',
    });

    const [error, setError] = useState('');

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const goToNext = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post(`${BACKEND_URL}/admin/login`, {
                email: formData.UserName,
                password: formData.Password,
            });

            console.log(response.data);
            // Save the token to localStorage or state management
            localStorage.setItem('administrator', JSON.stringify(response.data.admin));
            localStorage.setItem('adminToken', response.data.token);

            // Navigate to the dashboard
            navigate('/dashboard');
        } catch (error) {
            console.error('Login failed', error);
            setError('Login failed. Please check your credentials and try again.');
        }
    };

    const goToSignUp = () => {
        navigate('/sign-up');
    };

    const containerStyle = {
        backgroundImage: 'url("https://ftsaero.com/wp-content/uploads/2023/07/flight-planning.jpg")',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        minHeight: '100vh',
        width: '100%',
    };

    return (
        <div style={containerStyle}>
            <div className="bg-fts-blue/80 min-h-screen flex flex-col">
                <div className="flex flex-grow">
                    <div className="flex justify-center items-center w-full text-fts-gold p-8">
                        <form onSubmit={goToNext}>
                            <div className="text-center ">
                                <h1 className="font-bold text-[48px]">Sign In</h1>
                                <hr className="w-[90%] border-fts-gold border-2 my-4" />
                                <div>
                                    <div className="w-full text-start">
                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="username" className="font-medium text-[16px] mb-2 text-neutral-200">Username:</label>
                                            <input
                                                type="text"
                                                id="username"
                                                name="UserName"
                                                placeholder="Enter your username"
                                                value={formData.UserName}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2"
                                            />
                                        </div>

                                        <div className="flex flex-col mb-4">
                                            <label htmlFor="password" className="font-medium text-[16px] mb-2 text-neutral-200">Password:</label>
                                            <input
                                                type="password"
                                                id="password"
                                                name="Password"
                                                placeholder="Enter your Password"
                                                value={formData.Password}
                                                onChange={handleInputChange}
                                                className="border-2 border-fts-gold rounded-md px-4 py-2"
                                            />
                                        </div>
                                    </div>

                                    {error && <div className="text-red-500 mt-2">{error}</div>}

                                    <div className="flex justify-center items-center mt-12">
                                        <button
                                            type="button"
                                            onClick={goToSignUp}
                                            className="rounded-2xl px-4 py-2 text-neutral-200 mr-5 hover:text-fts-gold">
                                            No Account? Sign Up
                                        </button>
                                        <button
                                            type="submit"
                                            className="rounded-2xl bg-fts-gold hover:bg-fts-gold/70 px-4 py-2 text-fts-blue">
                                            Sign In
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default Login;
