import React, { useEffect, useState } from 'react';
import Breadcrumb from "../../assets/components/breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { HomeIcon } from "@heroicons/react/16/solid";
import { useNavigate, useParams } from "react-router-dom";
import { BACKEND_URL } from "../../Default";
import axios from 'axios';

const AddOtherPermitDetails = () => {
    const navigate = useNavigate();
    const { ref } = useParams();
    const [data, setData] = useState(null);
    const [pilotName, setPilotName] = useState('');
    const [pilotNationality, setPilotNationality] = useState('');
    const [previouslyOperatedInSriLanka, setPreviouslyOperatedInSriLanka] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/requests/request/${ref}`);
                if (response.data && response.data.length > 0) {
                    setData(response.data[0]);
                } else {
                    console.error('No data found for ref:', ref);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [ref]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!data) {
            console.error('No data available to submit.');
            return;
        }

        const updatedServices = {
            ...data,
            request_data: {
                ...data.request_data,
                pilot_name: pilotName,
                pilot_nationality: pilotNationality,
            }
        };

        const formData = {
            client_id: updatedServices.client_id,
            request_id: updatedServices.request_id,
            request_data: JSON.stringify(updatedServices.request_data),
            request_status: 'RECEIVED',
        };

        try {
            const response = await axios.post(`${BACKEND_URL}/requests/new`, formData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            // Fetching the CAA form path
            const pdfData = {
                request_id: ref,
            }
            await axios.post(`${BACKEND_URL}/pdf/caa-form/`, pdfData);

            // Example of handling getFile request if needed
            // const getFile = await axios.get(`${BACKEND_URL}/pdf/generate`, {
            //     headers: {
            //         'Content-Type': 'application/json'
            //     }
            // });

            console.log('Response from backend:', response.data);
            navigate(`/permit/details/${data.request_id}`);
        } catch (error) {
            console.error('Error sending data to backend:', error);
        }
    };

    const goBack = () => {
        navigate('/previous-page'); // Replace with the desired navigation path
    };

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Permit', url: '' },
        { label: 'New', url: '/permit/new' },
    ];

    return (
        <>
            <div className="flex flex-col">
                <main className="flex-grow">
                    <div className="w-full min-w-full px-4">
                        <div className="mt-3">
                            <Breadcrumb items={breadcrumbItems} />
                        </div>
                        <div className="mt-3">
                            <h1 className="font-bold text-gray-800 text-xl">Add Other Permit Details</h1>
                        </div>
                        <div className="mt-3">
                            {data && data.request_data ? (
                                <form onSubmit={handleSubmit} className="text-gray-800">
                                    <div className="bg-white py-2 px-4 rounded-md">
                                        <h2>Fill in the following fields</h2>
                                        <div className="flex max-w-full justify-center items-center">
                                            <div className="mt-3 flex justify-between gap-5">
                                                <div>
                                                    Request Type: <strong>{data.request_data.aircraftNature}</strong>
                                                </div>
                                                <div>
                                                    Aircraft Nature: <strong> {data.request_data.subCategory}</strong>
                                                </div>
                                                <div>
                                                    Flight
                                                    No.: <strong>{data.request_data.schedules?.[0]?.flightNo || 'N/A'}</strong>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            className="grid grid-cols-2 gap-4 mx-12 px-12 mt-3 justify-center items-center">

                                            <div className="col-span-2">
                                                <h5 className="font-bold">General Details of Aircraft Movement</h5>
                                            </div>
                                            <div className="mb-2">
                                                <label className="block">Select if operator has previously operated in
                                                    Sri Lankan Airports or Over Colombo FIR:</label>
                                                <div className="flex mt-2">
                                                    <div>
                                                        <label className="inline-flex items-center">
                                                            <input
                                                                type="radio"
                                                                className="form-radio"
                                                                value="yes"
                                                                checked={previouslyOperatedInSriLanka === 'yes'}
                                                                onChange={(e) => setPreviouslyOperatedInSriLanka(e.target.value)}
                                                            />
                                                            <span className="ml-2">Yes</span>
                                                        </label>
                                                    </div>
                                                    <div>
                                                        <label className="inline-flex items-center ml-5">
                                                            <input
                                                                type="radio"
                                                                className="form-radio"
                                                                value="no"
                                                                checked={previouslyOperatedInSriLanka === 'no'}
                                                                onChange={(e) => setPreviouslyOperatedInSriLanka(e.target.value)}
                                                            />
                                                            <span className="ml-2">No</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-span-2">
                                                <h5 className="font-bold">Aircraft Details</h5>
                                            </div>

                                            <div className="mb-2">
                                                <label className="block">Pilot Name:</label>
                                                <input
                                                    className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                    value={pilotName}
                                                    onChange={(e) => setPilotName(e.target.value)}
                                                />
                                            </div>

                                            <div className="mb-2">
                                                <label className="block">Pilot Nationality:</label>
                                                <input
                                                    className="border border-gray-300 px-3 py-2 rounded-md w-full"
                                                    value={pilotNationality}
                                                    onChange={(e) => setPilotNationality(e.target.value)}
                                                />
                                            </div>

                                            <div className="col-span-2">
                                                <h5 className="font-bold">On-Board Details</h5>
                                            </div>


                                        </div>
                                    </div>


                                    <div className="flex justify-between mt-4">
                                    <button
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold"
                                            type="button"
                                            onClick={goBack}>
                                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"/>
                                            Back
                                        </button>

                                        <button
                                            type="submit"
                                            className="text-fts-blue text-xl rounded-full bg-fts-gold px-4 py-4 hover:bg-fts-blue hover:text-fts-gold">
                                            Next
                                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"/>
                                        </button>
                                    </div>
                                </form>
                            ) : (
                                <p>Loading...</p>
                            )}
                        </div>
                    </div>
                </main>
            </div>
        </>
    );
};

export default AddOtherPermitDetails;
