import React, { useState } from 'react';
import Breadcrumb from "../assets/components/breadcrumb";
import { HomeIcon } from "@heroicons/react/16/solid";
import { TextField, Typography, Button } from "@mui/material";

const TimeCalculatorUtc = () => {
    const [utcTime, setUtcTime] = useState('');
    const [localTime, setLocalTime] = useState('');
    const [convertedUtcTime, setConvertedUtcTime] = useState('');
    const [convertedLocalTime, setConvertedLocalTime] = useState('');

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'UTC Calculator', url: '/time-calculator-utc' },
    ];

    const handleUtcChange = (e) => {
        setUtcTime(e.target.value);
    };

    const handleLocalChange = (e) => {
        setLocalTime(e.target.value);
    };

    const convertUtcToLocal = () => {
        if (utcTime) {
            const date = new Date(utcTime + 'Z');  // Adding 'Z' to treat input as UTC
            setConvertedLocalTime(date.toLocaleString());
        }
    };

    const convertLocalToUtc = () => {
        if (localTime) {
            const date = new Date(localTime);
            setConvertedUtcTime(date.toISOString());
        }
    };

    return (
        <>
            <div className="mt-3 flex justify-between">
                <div>
                    <Breadcrumb items={breadcrumbItems} />
                </div>
                <div>
                    <h5 className="font-bold">User</h5>
                </div>
            </div>

            <div className="pt-0">
                <h1 className="font-bold text-lg">UTC Time Zone Converter</h1>
            </div>

            <div>
                <div className="grid grid-cols-2 gap-4 justify-center items-center mt-12">
                    <div className="flex flex-col gap-4 px-8">
                        <TextField
                            label="UTC Time"
                            type="datetime-local"
                            value={utcTime}
                            onChange={handleUtcChange}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            className="bg-fts-blue text-white hover:bg-fts-gold py-1 rounded"
                            onClick={convertUtcToLocal}>
                            Convert to Local Time
                        </Button>
                        {convertedLocalTime && (
                            <Typography variant="body1">
                                <span className="font-bold">Local Time:</span> {convertedLocalTime}
                            </Typography>
                        )}
                    </div>
                    <div className="flex flex-col gap-4 px-8">
                        <TextField
                            label="Local Time"
                            type="datetime-local"
                            value={localTime}
                            onChange={handleLocalChange}
                            fullWidth
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Button
                            className="bg-fts-blue text-white hover:bg-fts-gold py-1 rounded"
                            onClick={convertLocalToUtc}>
                            Convert to UTC Time
                        </Button>
                        {convertedUtcTime && (
                            <Typography variant="body1">
                                <span className="font-bold">UTC Time: </span>{convertedUtcTime}
                            </Typography>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default TimeCalculatorUtc;
