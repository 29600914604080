import React, { useState } from 'react';
import { HomeIcon } from "@heroicons/react/16/solid";
import Breadcrumb from "../assets/components/breadcrumb";
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TableSortLabel,
    TextField,
    Checkbox
} from "@mui/material";

const Forms = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortedField, setSortedField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [selectedRows, setSelectedRows] = useState([]);

    const breadcrumbItems = [
        { label: <HomeIcon className="h-4 w-5" />, url: '/' },
        { label: 'Forms', url: '/downloads/forms' },
    ];

    // Sample data for forms
    const sampleForms = [
        { id: 1, name: 'Form A', country: 'Country X' },
        { id: 2, name: 'Form B', country: 'Country Y' },
        { id: 3, name: 'Form C', country: 'Country Z' },
        { id: 4, name: 'Form D', country: 'Country W' },
        { id: 5, name: 'Form E', country: 'Country V' },
        { id: 6, name: 'Form F', country: 'Country U' },
        { id: 7, name: 'Form G', country: 'Country T' },
    ];

    const handleSort = (field) => {
        const isAsc = sortedField === field && sortDirection === 'asc';
        setSortedField(field);
        setSortDirection(isAsc ? 'desc' : 'asc');
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            <div className="mt-3 flex justify-between">
                <div>
                    <Breadcrumb items={breadcrumbItems} />
                </div>

                <div>
                    <h5 className="font-bold">User</h5>
                </div>
            </div>

            <div className="pt-0">
                <h1 className="font-bold text-lg">Forms</h1>
            </div>

            <div className="mt-4">
                <div className="flex justify-between items-center my-4">
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </div>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <TableSortLabel
                                        active={sortedField === 'name'}
                                        direction={sortDirection}
                                        onClick={() => handleSort('name')}
                                    >
                                        <strong>Form Name</strong>
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell>
                                    <strong>Download</strong>
                                </TableCell>
                                <TableCell>
                                    <strong>Country</strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sampleForms
                                .filter((form) => form.name.toLowerCase().includes(searchTerm.toLowerCase()))
                                .sort((a, b) => {
                                    const fieldA = a[sortedField] ?? '';
                                    const fieldB = b[sortedField] ?? '';
                                    const comparison = fieldA.localeCompare(fieldB);
                                    return sortDirection === 'asc' ? comparison : -comparison;
                                })
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((form) => (
                                    <TableRow key={form.id}>
                                        <TableCell>{form.name}</TableCell>
                                        <TableCell>
                                            <button className="text-blue-600 hover:underline">Download</button>
                                        </TableCell>
                                        <TableCell>{form.country}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={sampleForms
                        .filter((form) => form.name.toLowerCase().includes(searchTerm.toLowerCase()))
                        .length
                    }
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </>
    );
};

export default Forms;
