import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@material-tailwind/react";
import Dashboard from "./Dashboard";
import Login from "./User/Login";

function App() {
    return (
        <ThemeProvider>
            <Router>
                <div className="font-montserrat min-w-screen min-h-screen w-full h-full bg-fts-blue p-0">

                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/*" element={<Dashboard />} />


                    </Routes>

                </div>
            </Router>
        </ThemeProvider>
    );
}

export default App;