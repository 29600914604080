import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChevronDown, faChevronRight, faClock, faHome, faBars,
    faLock
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from "@fortawesome/free-solid-svg-icons/faFile";
import { Link, useNavigate } from "react-router-dom";

import logo from '../images/branding/fts-logo.png';

const Sidebar = ({ isCollapsed, toggleSidebar }) => {

    const navigate = useNavigate;
    const [openMenus, setOpenMenus] = useState({});

    const toggleMenu = (menu) => {
        setOpenMenus(prevState => ({ ...prevState, [menu]: !prevState[menu] }));
    };

    const handleSignOut = () => {
        localStorage.removeItem('adminToken');
        localStorage.removeItem('administrator');
        navigate('/login');
    };

    return (
        <aside className={`bg-fts-gold text-white min-h-screen p-4 transition-all duration-300 ${isCollapsed ? 'w-16' : 'w-64'}`}>

            <div className="flex justify-between items-center mb-6">
                <button onClick={toggleSidebar} className="focus:outline-none">
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </div>

            <div className="flex justify-between items-center mb-6">
                {!isCollapsed && <img src={logo} alt="FTS Company Logo" className="bg-neutral-200 rounded" />}
            </div>
            <nav>
                <ul className="space-y-2">
                    <li className="mt-12">
                        <Link to={`/`}>
                            <div
                                className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                            >
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faHome} className="mr-2" />
                                    {!isCollapsed && <span>Home</span>}
                                </div>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link to={`/permit/all-requests`}>
                        <div
                            className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer"
                        >
                            <div className="flex items-center">
                                <FontAwesomeIcon icon={faFile} className="mr-2" />
                                {!isCollapsed && <span>Permit</span>}
                            </div>
                        </div>
                        </Link>

                    </li>

                    <li>
                        <hr />
                    </li>

                    <li>
                        <div className="flex items-center justify-between py-1 pb-0 px-3 ">
                            <div className="flex items-start">
                                {!isCollapsed && <span className="text-xs">TOOLS</span>}
                            </div>
                        </div>
                    </li>
                    <li>
                        <Link to={`/tools/time-calculator-utc`}>
                            <div className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer">
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faClock} className="mr-2" />
                                    {!isCollapsed && <span>UTC Calculator</span>}
                                </div>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <hr />
                    </li>

                    <li>
                        <div className="flex items-center justify-between py-1 pb-0 px-3 ">
                            <div className="flex items-start">
                                {!isCollapsed && <span className="text-xs">DOWNLOADS</span>}
                            </div>
                        </div>
                    </li>
                    <li>
                        <Link to={`/downloads/forms`}>
                            <div className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer">
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faFile} className="mr-2" />
                                    {!isCollapsed && <span>Forms</span>}
                                </div>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <hr />
                    </li>

                    <li>
                        <Link onClick={handleSignOut}>
                            <div className="flex items-center justify-between py-2 px-3 hover:bg-gray-700/70 hover:backdrop-blur-lg rounded-2xl cursor-pointer">
                                <div className="flex items-center">
                                    <FontAwesomeIcon icon={faLock} className="mr-2" />
                                    {!isCollapsed && <span>Sign Out</span>}
                                </div>
                            </div>
                        </Link>
                    </li>

                </ul>
            </nav>
        </aside>
    );
};

export default Sidebar;
